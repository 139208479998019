/*
    Taken From : http://serenedestiny.com/blog/web-development/lets-create-the-perfect-css-reset; 
    Reset all your HTML Element to condition zero.
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong,tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    vertical-align: baseline;
    background: transparent;
}
body {
    line-height: 1;
}

textarea { overflow: auto; margin: 0;}
input { border: 0px solid white; margin: 0; padding: 0; }

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
    content: "";
    content: none;
}

/* remember to define focus styles! */
:focus {
    outline: 0;
}

/* remember to highlight inserts somehow! */
ins {
    text-decoration: none;
}
del {
    text-decoration: line-through;
}

/* tables still need 'cellspacing="0"' in the markup */
table {
    border-collapse: collapse;
    border-spacing: 0;
}





/* global
----------------------------------------------------------------------------------------------*/
a {color:#015b7d; text-decoration: none; outline: none }
a:hover {cursor: pointer; color:#333333; }
 
p {
    margin:1px 0 15px; 
    line-height:20px;
}
input, textarea, select {
    font-size:12px; 
    font-family:arial,tahoma, times new roman; 
    border:none; 
    border:1px solid #D8D8D8; 
    padding:4px; 
    margin:0;
    color:#666;
}

strong { font-weight: bold; }
em { font-style: italic; }
del { text-decoration: line-through; }
th { font-weight: normal; }

.left { float: left; display: inline; }
.right { float: right; display: inline;} 
.italic {font-style:italic;}
.bold {font-weight:bold;}
.clear { 
    clear:both; 
    padding:0; 
    margin:0; 
}
.before_clear:before,
.after_clear:after{
    content: '';
    display: block;
    clear: both;
}
h1, h2, h3, h4, h5, h6 {font-weight:normal;} 
h1 {font-size:32px;}
h2 {font-size:28px;}
h3 {font-size:24px;}
h3 {font-size:18px;}
h5 {font-size:16px;}
h6 {font-size:14px;}

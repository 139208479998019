@import "../css/reset";
@import "sass-lib";
 
@media screen and (min-width:768px){
	div.address-list { display: none; }
	div.testimonial-txt {
		div.top {
			.title-desc { max-width: 87%; }
		}
	}
}

@media screen and(min-width:1px) and(max-width:768px){
	span.expand {
	    padding-right: 20px;
	    @include boxSizing(border-box);
	    height: 20px;
	    overflow: hidden;
    }
    .star-container {
    	display: block;
	    margin-left: 0px;
	    padding-left: 0px;
	    &::before { display:none; }

	    .rating { direction: rtl; }
	    .rating-star { display: inline-block; float: none; }
	}
	div.testimonial-txt {
		div.top {
			.title-desc { max-width: 81%; }
		}
	}
}
@media screen and (max-width:424px){
	section.coverage {
		.media-container {
			.list {
				.inner {
				    min-height: auto; position: relative; padding: 10px; @include boxSizing(border-box);
				    .content-wrapper { position: relative; @include boxSizing(border-box); left: 0px; right: 0px; top: 0px; 
				    	img { height: 20px; right: 20px; bottom: 22px;  }
				    }
				    p.coverage-title { margin-bottom: 72px; line-height: 22px; }
				}
			}
		}
	}
}



@media screen and(min-width:1px) and(max-width:425px){
	span.expand { display: block; min-width: 130px; max-width: 160px; height: 26px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; padding-right: 25px; 
		span.acc-arrow { right: 9px; }
	}
	div.gcaptcha { position: relative; left: -20px; }
	div.wrapper-tutor-list { padding: 0px 0px; }
	section.about-intro { padding-top:0px; 
		div.title { top: 0px; }
	}
	div.tutors-list .name-alma .name {
	    max-width: 112px;
	    max-height: 20px;
	}
	div.input-group {
		span {
		    font-size: 12px;
		}
		input[type="text"],input[type="email"], textarea { font-size: 12px; }
		.input_file span { font-size: 12px; }
		table {
			margin: 20px 0px 0px;
			td:last-child {
			    padding: 0px 15px 0px 15px;
			}
		}
	}
	div.menu-popup .menu-socmed {
	    bottom: -80px;
	}
	div.rating-list {
		.stars {
			display: block;
			&::after {
			    margin-right: 0px;
			    margin-left: 0px;
			    display: none
			}
		}
	}
	figure.intl { width: 80%; }
	form {
		&.basic {
			table {
				tr {
					th { display: none; }
					td { display: block; 
						&:nth-child(1) {
							margin-bottom: 10px;
						}
						&:last-child { margin-bottom:30px; }
						&::before { font-size:12px;content:''; position:relative; display: block; width: 100%; top: 8px; }
						&:nth-child(1)::before { display: none; }
						input { margin-top: 7px; margin-bottom: 5px; }
					}
				}
				&.education , &.experience , &.achievement {
					tr.additional-list { display: block; margin-bottom: 85px; }
					span.delete { right: auto; top: auto; bottom: -45px; }
				}
				&.education {
					tr {
						td { display: block; 
							&:nth-child(2)::before { content:'name of school'; }
							&:nth-child(3)::before { content:'graduation year'; }
						}
					}
				}
				&.experience {
					tr {
						td { display: block; margin-left: 10px;
							&:nth-child(1) {
								margin-bottom: 0px;
							    display: inline-block !important;
							    float: left;
							    position: relative;
							    margin-left: 0px !important;
							    top: 4px;
							}
							&:nth-child(2)::before { content:'name of company'; }
							&:nth-child(3)::before { content:'position'; }
							&:nth-child(4)::before { content:'year'; }
						}
					}
				}
				&.achievement {
					tr {
						td { display: block; margin-left: 10px;
							&:nth-child(1) {
								margin-bottom: 0px;
							    display: inline-block !important;
							    float: left;
							    position: relative;
							    margin-left: 0px !important;
							    top: 4px;
							}
							&:nth-child(2)::before { content:'name of company'; }
							&:nth-child(3)::before { content:'position'; }
							&:nth-child(4)::before { content:'year'; }
						}
					}
				}
			}
		}
	}
	div.menu-popup {
		.menu-search {
			padding-top: 30px !important;
			form {
				input {
				    min-width: 100px;
				    font-size: 18px;
				    padding: 12px 40px 12px 8px;
				}
			}
		}
		.menu-box {
			a , b { font-size: 16px !important; }
			b { padding: 10px 0px !important; }
		}
		.menu-socmed {
			li {
				span {
				    width: 23px;
				    height: 23px;
				    background-size: 100%;
				}
			}
		}
	}
	div.tutors-list .triangle {
	    width: 36px !important;
	    height: 31px !important;
	}
	div.tutors-list:nth-child(even) .triangle, div.tutors-list:nth-child(odd) .triangle {
	    right: -6px !important;
	}
	div.tutors-list .triangle {
	    top: 4px !important;
	}
	div.pagination {
		div.row div.item {
		    margin: 0px 5px;
		}
		div.row div.item a {
		    padding: 12px 0px;
		}
	}
	div.testimonial-txt {
		div.top {
			.logo { width: 52px; position: relative; top: 5px; }
			.title-desc { margin-left: 10px; max-width: 75%; overflow: hidden; }
		}
	}
	div.news-list {
		.content {
			.logo {
				&::before { width:69px; min-width: 69px; }
			}
		}
	}
	div.career-list {
		.inner { padding: 150px 0px !important; }
		.status-role {
			.status {
			    font-size: 18px !important; margin-bottom: 10px;
			}
			.role {
			    font-size: 22px !important;
			}
		}
	}
	header {
		&.active div.right .hamburger { top: 4px; }
		div.left {
			.logo img {
			    max-height: 42px;
			}
		}
		div.right {
			.language { right: 87px; top: 1px; }
			.hamburger {
			    top: 1px;
			    padding: 8px 0px;
			    margin-left: 20px;
			}
			.join {
				margin-top: -5px;
			    font-size: 9px;
			    padding: 9px 5px;
			}
		}
	}
	section {
		/*&:not(.program) { display: none; }*/
		&.main-slider {
			margin-bottom: 30px !important; background-color: #000;
			.basic {
			 	.caption {
			 		.inner {
			 			h1 {
						    font-size: 28px !important; line-height: 34px;
						}
			 		}
			 	}
			}
			.video .caption .play { bottom: -77px !important; }
		}
		&.about-intro {
			.decor {
			     display: block;
			}
			div.title {
			    padding: 0px 30px; width: 100%;
			}
		}
		&.program {
			overflow: hidden;
			.slider {
				max-width: 100%;
				button.slick-prev, button.slick-next {
				    width: 26px; height: 24px; bottom: 54px !important;
				}
				.slide {
					padding-right: 0px;
					figure{
						position: relative; width: 100%; height: 100%; overflow: hidden;
						img {
							position: absolute;
						    top: 50%;
						    left: 50%;
						    transform: translate(-50% , -50%);
						    max-width: none;
						    height: 100%;
						    min-width: 100%;
						}
					}
				}
			}
		}
		&.work {
			margin-bottom: 30px;
			div.work-list .list span.work { zoom: 0.55; }
			div.work-list .list span.tooltip-text { left: -47% !important; width: 200px; }
		}
		&.testimonial {
			span.decor-right { display: none; }
			.wrap-list {
				.list {
					img {
					    width: auto !important;
					    height: 100% !important;
					}
				}
			}
		}
		&.banner {
			div.img { height: 132px; }
			div.left-content { height: 150px; }
			div.left-content {
				.inner {
					bottom: 9px;
					h1 {
					    font-size: 14px; line-height: 18px;
					}
				}
			}
		}
		&.double-column , &.single-column  {
			aside {
				div.side-menu {
					ul { width: 153px; 
						li { word-break: break-word; }
					}
				}
			}
			div.bg-nav {
			    width: 73px ;
			    height: 40px;
			    bottom: 0px;
			}
			aside {
				h2 { font-size: 18px !important; line-height: 24px !important; }
			}
			article {
				h3 {
					font-size: 16px !important; line-height: 20px !important; max-width: 100%;
					&.meeting {
						&::after{ content:"" !important; height: 35px; width: 40px; background-size: 100%; background-image: url("../images/material/meeting.png"); }
					}
					&.news {
						b { font-size: 16px !important;  }
					}
				}
				h1 { font-size: 30px; line-height: 45px; }
				img { margin: 5px 0px; }
            	p , b , ul li , ol li { font-size: 14px !important; line-height: 22px !important; margin: 22px 0px; }
			}
			div.share-socmed {
			    top: -65px !important;
			}
		}
		&.double-column {
			aside {
				div.side-menu {
					ul {
						li {
							a { font-size: 13px; }
						}
					}
				}
			}
			article {
				.bg-nav {  }
			}
		}
		&.not-found {
			.content {
				width: 66% !important; padding: 0px 15px; @include boxSizing(border-box);
				.inner {
				    max-width: 75%;
				    &::after {
					    right: -10px !important;
					    bottom: -20px;
					    max-width: 70px;
					}
				}
			}
			a.highlight-more { 
				font-size: 12px !important; 
				span.arrow-right , span.arrow-right-blue { top: -1px !important; }
			}
			h1 , h3 { padding-right: 0px; }
			h1 {  font-size: 38px !important; line-height: 45px; margin-bottom: 10px; }
			h3 { font-size: 14px !important; line-height: 18px; }
		}
		.slide-about-teams .nav, .slide-about-intl .nav {
		    width: 66px !important;
		    height: 50px;
		}
		div.slide-about-intl , div.slide-about-teams {
			button , a { zoom:0.6; bottom: 22px; 
				&.slick-prev { right: 78px; } 
				&.slick-next { right: 34px; } 
			}
			.video .caption {
				top: 5px; bottom: 5px;
				.play {
					right: -25px;
				    width: 50px;
				    height: 49px;
				    &:hover {		
				    	right: -22px;
					    width: 40px;
					    height: 39px;
				    }
				}
				b::after {
				    bottom: 7px;
				    width: 30px;
				}
			}
		}
		div.slide-nav {
			div.item { 
				font-size: 10px; 
				&.slick-current { font-size: 14px; font-weight: bold; color: $c_blue; }
			}
			button.slick-prev { left: -27px; }
			button.slick-next { right: -27px; }
		}
		.slide-news-detail {
			button.slick-prev { 
				@include rotation(0deg); left: auto; bottom: auto;
				&::after {
				    right: -12px;
				    top: -6px;
				    bottom: -6px;
				}
			}
			button.slick-next { @include rotation(0deg); left: auto; bottom: auto; }
		}
		.wrap-slider-news {
			.white-bg {
			    left: auto; width: 45px; height: 73px;
			}
		}
	}
	footer {
		 .seen-on {
		 	ul {
		 		 li {
				    margin: 15px 0px 0px 0px; width: 33.3%; padding: 0px 10px; @include boxSizing(border-box);
				    /*&:nth-child(n+4) { display: none; };*/
				}
		 	}
		 }
		.rights { font-size: 9px; line-height: 12px !important; }
	}
}

@media screen and(min-width:426px) and(max-width:570px){
	section.double-column div.share-socmed, section.single-column div.share-socmed {
    	top: -75px !important;
	}
}

@media screen and(min-width:537px) and(max-width:767px){
}

@media screen and(min-width:1px) and(max-width:767px){
	div.map { height: 341px; }
	.wrap-popup {
		.box-popup {
		    margin: 5% auto;
		    position: relative;
		    padding: 10px 20px;
		    box-sizing: border-box;
		    a { top: -5px; right: 6px; }
		}
	}
	section.seen-on {
		position: relative; bottom: auto;
	}

	b.title, b.title-slider {
	    font-size: 18px;
	    line-height: 26px;
	}
	div.tutors-list {
		width: 100%;
		&:nth-child(even) , &:nth-child(odd) { padding-left: 0px; padding-right: 0px; 
			.triangle { right: 0px; }
		}
		.triangle { width: 39px; height: 43px; }

	}
	div.teams-list , div.teams-list.teams-right  {
		margin: 10px 0px;
		.bio {
			.title-name {
			    float:none;clear:both; margin-left: 0px; max-width: 100%; top: auto; margin-top: 20px; display: inline-block; 
				p.title { font-size: 14px; line-height: 12px; margin: 0px; }
				b.name { font-size: 18px; margin: 0px; }
				span.decor { position: absolute; bottom:-10px; top: auto; left: 0px; max-width: 39px; }
			}
			.image {
			    width: 100%;
			    height: auto;
			    img { left: 0px; max-width: 250px; position: relative; }
			}
			.number { font-size: 33px; }
		}
	}
	div.teams-list.teams-right {
		.bio {
			.title-name {
			    position: absolute; bottom: -58px;
			}
			.image {
				text-align: right;
			    img { left: auto; right: 0; }
			}
			.number { right: 0px; top: 30px; }
		}
		.desc { margin-top: 90px; }
	}
	div.menu-popup {
	    padding-top: 12px; overflow-x: hidden; max-width: 100%;
	    .menu-search {
		    padding-top: 50px; margin-bottom: 15px;
		}
		.menu-box {
			b {
			    line-height: 18px;
			    padding: 14px 0px;
			}
			a { font-size: 18px; }
		}
	}
	div.title {
		h6 {  }
		h2 { font-size: 24px; }
		p {
			&.hasborder {
				padding-left: 40px;
				span.decor {
				    width: 30px; top: 9px;
				}
			}
		}
	}
	div.wrapper, div.wrapper-2 {
	    width: 88.2%;
	    &.wrap-menu { margin-bottom: 170px; }
	}
	div.price-list {
		.price {
			position: relative;
		    top: 100%;
		    left: 0;
		    right: 0;
		    transform: translateY(0%);
		    max-width: 100%;
		    padding: 28px;

		    P { font-size: 31px !important; }
		}
		.list {
		    margin-left: 0px;
		    padding: 35px 31px;
		    background-size: cover;
		    font-size: 18px;
		    line-height: 24px;
		}
	}
	div.file-download {
		.file-title { line-height: 24px; margin: 10px 0px; }
	}
	div.testimonial-txt {
		padding-bottom: 14px;
		.top {
			.title-desc { top: 7px; }
		}
		.bottom {
			p { font-size: 16px !important; line-height: 22px !important; margin: 15px 0px !important; }
		}
	}
	div.news-list {
		.content {
			margin-top: 15px;
			.content-desc {
			    margin-left: 0px;
			}
			.logo { max-width: auto; float: none; display: block; min-height: 30px;
				img { width: auto; }
			}
		}
	}
	div.wrapper-contact {
	     padding: 0px 0px; 
	     form {
	     	div.input-group {
	     		div.gcaptcha { left: -9px !important; }
	     	}
	     }
	}
	div.address-list {
		margin: 20px 0px; display: inline-block;
		table {
			tr {
				td { padding:5px 0px; color: $c_black; 
					a { color: $c_black; line-height: 20px; font-size: 14px; }
					&:nth-child(2) { min-width: 20px; text-align: center; }
				}
			}
		}
	}
	div.wrap-first-meeting { 
		width: 100% !important; 
		div.share-socmed { right: 10px; }
	}
	div.career-list {
		width: 100%; height: auto;
		&:nth-child(odd) , &:nth-child(even) { margin-right: 0px; margin-left: 0px; }
		.inner { position: relative; padding: 180px 0px; top: 0%; transform: translateY(0%); }
	}
	div.wrapper-fluid.hasborder {
	    padding: 10px 30px 10px 7px;
	}
	div.wrap-form-contact { 
		width: 100% !important; 
		aside { width: 88.2%; margin: 0 auto; }
	}
	.wrap-faq , .wrap-tc { padding-top: 0px !important; }
	section {
		&.main-slider {
			margin-bottom: 100px;
			.main-slides .slick-dots button { margin: 10px 0px; 
				&::before {
					width:19px; height: 2px;
				}
			}
			.main-slides .slick-dots .slick-active button::before {
			    width: 38px !important;
			}
			.main-slides { margin: 0px 0px; }
        	.basic , .video {
        		height: 100vh; max-height: 768px;
        		img { height: 100vh; max-height: 768px; max-width: 500%; }
        	}
        	.basic {
        		.caption {
        			.inner { max-width: 68%; margin: auto; }
        			h1 { font-size: 36px; padding: 15px 0px !important; }
        			p { font-size: 14px !important; margin: 15px 0px !important; }
        		}
        	}
        	.video {
        		.caption {
        			max-width: 100%;
        			.inner { padding: 0px 60px; @include boxSizing(border-box); width: 100%; }
        			.play { right: 0 !important; left: 0; margin: auto; top: auto; bottom: -75px; transform: translateY(0%); width: 60px; height: 59px; 
        				&:hover { width: 50px; height: 49px; bottom: -115px; }
        			}
        			h1 { font-size: 24px; line-height: 38px; 
        				span.border { bottom: 0px; right: 48px; }
        			}
        		}
        	}
		}
		&.seen-on {
			.wrapper { width: 100%; }
			.decor { display: none; }
			.inner {
				.slides-mobile {
					.slide { padding: 6px; @include boxSizing(border-box); }
				}
			}
		}
		&.about-intro {
			.decor-big { display: none; }
			.wrapper { width: 100%; }
			.title {
				top: 0px; margin-bottom: 60px; width : auto !important; padding: 0px 50px; @include boxSizing(border-box);
			}
			div.images {
				clear: both; padding-left: 0px; max-width:100%;
			}
		}
		&.program {
			.decor-1 , .decor-2 , .decor-3 { display: none; }
			span.big-decor { bottom: 0px; }
			.title { padding: 0px 21px; @include boxSizing(border-box); }
			.wrapper-fluid { padding: 20px 0px 29px 0px; }
			.caption-fixed { width: 100%; top: auto; padding: 21px 0px; min-height: 130px; right: 0px;
				p { display: none; }
			}
			.inner {
			    padding: 0px 0px 0px 0px; width: 50%; margin: 0 auto;
			}
			.slider {
				button.slick-prev, button.slick-next { top: auto; bottom: 45px; transform: translateY(0%); 
					&:hover { bottom:52px; }
				}
				.slide { height: 320px; padding-right: 0px;
					img { width: auto; max-width: 500%; height: 100%; }
				}
			}
		}
		&.work {
			.wrapper { width: 100%; }
			div.title {
				padding: 0px 50px; @include boxSizing(border-box);
			}
			div.title.hasborder span.decor {
				display: none;
			}
			span.decor { display: none; }
			div.work-list {
    			padding: 84px 0px 5px !important;
				button { display: none !important; }
				.list { min-height: 300px; width: 100px; padding: 0px 20px;
					span.tooltip-text { left: -24% !important; right: 0; margin: auto; 
						&::before { top: -8px; left: 44% !important; }
					}
					&.slick-active {
						.tooltip-text { display: inline; }
					}
				}
			}
		}
		&.testimonial {
			padding-top: 35px;
			.title { padding: 0px 21px; }
			span.decor-3 { display: none; }
			span.big-bg-decor { display: none; }
			.highlight {
				width: 100%; padding: 0px; margin-bottom: 30px;
				.highlight-titledesc {
					padding-left: 15px;
					span.decor { width: 60px;height: 2px; left: 15px; }
					p.highlight-title { font-size: 16px; margin-bottom: 10px; font-weight: 700; }
					p.highlight-desc { margin-bottom: 33px; }
				}
			}
			.wrap-list {
				width: 100%; padding-left: 0px;
				.list {
					img { width: 100%; height: auto; }
					.back {
						.inner {
							bottom: 10px; right: 10px;
							p.title-testi { font-size: 16px; }
							a.read-more { font-size: 12px; 
								span.arrow-right { top: 0px; }
							}
						}
					}
				}
				div.container-view-all { padding:33px 14px 0px; }
			}

		}
		&.coverage {
			.wrapper { padding: 0px 15px; @include boxSizing(border-box); }
			.media-container { 
				width: 100%; padding: 0px 0px; margin-top: 50px; 
				.list {
					padding-right: 0px; width: 100%; margin-bottom: 10px;
					.inner {
						.triangle-corner { width: 40px; height: 41px; }
						p.coverage-title { font-size: 16px; padding: 0px 15px; }
						.content-wrapper {
							img { height: 19px; }
						}
					}
				}
			}
		}
		&.join {
			margin-bottom: 200px;
			.wrap-join { width: 100%; }
		}
		&.banner {
			.img { height: 199px; margin-left: 45%; }
			.left-content { 
				width: 45%; height: 225px; 
				.inner {
					right: 11px; bottom: 19px; max-width: 75%; word-wrap: break-word;
					h1 { font-size: 21px; line-height: 28px; }
					ul.breadcrumb { display: none; }
				}
				&::after { bottom: -20px; }
			}
		}
		&.double-column , &.single-column {
			div.share-socmed { position: absolute; top: 8px; z-index: 1; }
			aside {
				ul.socmed-contact { margin-bottom: 0px; }
				p { font-size: 18px; line-height: 26px; }
	        	b { font-size: 16px; }
	        	h2 { font-size: 24px; line-height: 30px; margin: 0px 0px 15px 0px; }
	        	a.btn { width: 100%; max-width: 100%; font-size: 16px; padding: 14px 14px; }

			}
			div.side-menu { top: 0px; left: 48%; position: absolute; }

			article {
				padding-top: 50px;
				h3 { font-size: 24px; line-height: 30px; 
					&.qs { font-size: 18px; line-height: 26px; }
				}
            	p , b , ul li , ol li { font-size: 18px; line-height: 28px; margin: 22px 0px; }
            	.title-desc {
            		p { font-size: 14px; line-height: 14px;}
            		b { font-size: 18px; } 
            	}
            	.mobile-small-p {
            		/*p , b , ul li , ol li { font-size: 14px; line-height: 20px; margin: 12px 0px; }*/
            		.title-desc {
            			margin-bottom: 30px;
            			p { margin: 5px 0px; }
            		}
            	}
			}
		}
		&.single-column {
			margin-top: 0px;
			&.has-socmed {
				margin-top: 0px;
			}
		}
		&.not-found {
			.content {
				width: 60%;
				.inner {
				    max-width: 75%;
				    &::after {
					    right: -20px;
					    bottom: -20px;
					    max-width: 70px;
					}
				}
			}
			a.highlight-more { 
				font-size: 16px; 
				span.arrow-right , span.arrow-right-blue { top: -2px; }
			}
			h1 , h3 { padding-right: 0px; }
			h1 {  font-size: 42px; line-height: 45px; margin-bottom: 10px; }
			h3 { font-size: 16px; line-height: 18px; }
		}
		.slide-news-detail {
			.slick-list { height: auto; }
			.item {
				img.main-image { max-width: 100%; width: 100%; margin-top: 0px; }
				.content { margin-left: 0%; width: 100%; 
					.top { display: block; margin-bottom: 0px; text-align: left; 
						img { margin: 5px 0px; max-height: 26px; width: auto; }
					}
					.content-desc { margin-top: 0px; display: inline-block; 
						p.title { margin: 20px 0px 52px 0px; 
							&::after { left: 0px; }
						}
					}
				}
				section .slide-news-detail .item .content .content-desc p.title::after {
				    position: absolute;
				    bottom: -20px;
				    left: -20px;
				    width: 101px;
				    height: 4px;
				    background-color: #015b7d;
				    content: '';
				}
			}
			button.slick-prev { 
				left: auto; bottom: auto; top: 53.5%; right: 50px;
				&::after {
					width:2px;
					height: auto;
				    right:-4px;
				    left: auto;
				    top: -6px;
				    bottom: -6px;
				}
			}
			button.slick-next { left: auto; bottom: auto; top: 53.5%; right: 10px; }
		}
		.wrap-slider-news {
			.white-bg {
			    width: 90px; height: 52px; left: auto; top: 50%; right: -1px; bottom: auto;
			}
		}
		.slide-nav {
		    width: 80%; margin: 0 auto;
		    float: none;
		    button.slick-prev {
			    margin-bottom: 0px; @include rotation(-90deg); position: absolute; top: 30px; left: -35px; 
			}
			button.slick-next {
			    margin-top: 0px; @include rotation(-90deg); position: absolute; top: 35px; right: -40px;
			}
			div.item {
				text-align: center; padding-top: 20px; @include transition(all 0.4s ease-in-out);
				&::before {
				    width: 1px; height: 17px; background: #4b4b4b; left: 0; right: 0; margin: auto; position: absolute;
				}
				&.slick-current {
					padding-top: 50px;
					&:hover:before, &::before {
					    width: 2px;
					    height: 40px;
					    background: #015b7d;
					    top: -5px;
					}
				}
			}
		}
		.slide-about-history {
		    width: 100%;
		    padding-left: 0px;
		}
	}
	footer {
		.col-l-four:nth-child(5) {
		    width: 100%;
		}
		.seen-on ul {
			li {
			    width: 33.3%;
			}
		}
		.rights {
			text-align: center; padding: 10px 30px; line-height: 15px;
		}
	}
}

@media screen and(min-width:1px) and(max-width:767px){
	section.coverage {
		.media-container {
			.list {
				.inner {
					.content-wrapper {
					    right: 10px;
					}
				}
			}
		}
	}
}
@media screen and(min-width:600px) and(max-width:767px){
	section.program .slider .slide img {
	    height: auto;
	    width: 125%;
	}
}
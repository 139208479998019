/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : FrontEnd Team  
==============================================================================================*/

/* import bourbon - http://bourbon.io/docs */
@import "bourbon/bourbon"; 

@mixin rotation($attr){
     -ms-transform: rotate($attr); /* IE 9 */
    -webkit-transform: rotate($attr); /* Chrome, Safari, Opera */
    transform: rotate($attr);
}
@mixin transition($attr){
    transition: $attr;
    -webkit-transition: $attr;
    -moz-transition: $attr;
    -ms-transition: $attr;
    -o-transition: $attr;
}
@mixin appearance($attr){
    -webkit-appearance  : $attr;
   -moz-appearance      : $attr;
   appearance           : $attr;
}
@mixin borderRadius($attr){
    border-radius: $attr;
    -webkit-border-radius: $attr;
    -moz-border-radius: $attr;
    -ms-border-radius: $attr;
    -o-border-radius: $attr;
}
@mixin boxShadow($attr){
    box-shadow:  $attr;
    -webkit-box-shadow: $attr;
    -moz-box-shadow: $attr;
    -ms-box-shadow: $attr;
    -o-box-shadow: $attr
}
@mixin placeholder {
    ::-webkit-input-placeholder {@content}
    :-moz-placeholder           {@content}
    ::-moz-placeholder          {@content}
    :-ms-input-placeholder      {@content}  
}
@mixin boxSizing($attr){
    -webkit-box-sizing: $attr;
    -moz-box-sizing: $attr;
    box-sizing: $attr;
}
@mixin absolute($top,$right,$bottom,$left){
    position: absolute;
    top: $top;
    right: $right;
    bottom:$bottom;
    left: $left;
}
@mixin fixed($top,$right,$bottom,$left){
    position: fixed;
    top: $top;
    right: $right;
    bottom:$bottom;
    left: $left;
}

.after_clear:after,.wrapper:after,section:after{clear:both;display: block;content:" "}
.before_clear:before{clear:both;display: block;content:" "}
.text-left{text-align: left}
.text-right{text-align: right}
.text-center{text-align: center}
.text-uppercase { text-transform: uppercase; }
.qs , .qs * { font-family: "Quicksand", sans-serif !important; }

/*color global*/
$c_white : #ffffff;
$c_blue : #015b7d;
$c_grey : #999999;
$c_grey2 : #f3f3f3;
$c_grey3 : #cccccc;
$c_grey4 : #e1e1e1;
$c_grey5 : #d6d6d6;
$c_grey6 : #555555;
$c_dark : #404040;
$c_lightblue : #40bad2;
$c_lightblue2 : #4abed4;
$c_lightblue3 : #80adbe;
$c_black : #333333;

.text-blue { color: $c_blue; }
.text-grey { color: $c_grey3; }
.text-white { color: $c_white; }